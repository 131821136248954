import './App.css';
import React, { useState, useEffect } from 'react';

// 定义一个灯光类
class Light {
  constructor(name, scenes) {
    this.name = name;
    this.scenes = scenes;
  }
}

// 创建灯光实例
const lowBeam = new Light('近光灯', ['请开启前照灯', '会车', '通过有信号灯控制的路口', '夜间同方向近距离跟车行驶', '在有路灯照明良好的道路行驶']);
const highBeam = new Light('远光灯', ['进入无照明或照明不良的道路行驶']);
const hazard = new Light('示廓灯加危险报警灯', ['在路边临时停车']);
const alternating = new Light('交替使用远近光灯', ['超车', '通过急弯', '通过坡路', '通过拱桥', '通过没有交通信号灯的路口', '通过人行横道']);

// 灯光列表
const lights = [lowBeam, highBeam, hazard, alternating];

// 场景列表
let scenes = [];
lights.forEach(light => {
  scenes.push(...light.scenes);
});

function App() {
  const [selectedLight, setSelectedLight] = useState('');
  const [randomScene, setRandomScene] = useState('');
  const [correctLight, setCorrectLight] = useState('');
  const [feedback, setFeedback] = useState('');
  const [feedbackType, setFeedbackType] = useState('');

  // 随机选择场景
  const getRandomScene = () => {
    const scene = scenes[Math.floor(Math.random() * scenes.length)];
    setRandomScene(scene);
    setCorrectLight(getCorrectLight(scene));
    setFeedback('');
    setSelectedLight('');
  };

   // 获取正确的灯光
   const getCorrectLight = (scene) => {
    for (const light of lights) {
      if (light.scenes.includes(scene)) {
        return light.name;
      }
    }
    return '';
  };


  // 处理选择
  const handleSelect = (event) => {
    setSelectedLight(event.target.value);
    if (event.target.value === correctLight) {
      setFeedback('✅ 恭喜你答对了！');
      setFeedbackType('success');
    } else {
      setFeedback(`❌ 答错了！正确答案应该使用: ${correctLight}`);
      setFeedbackType('error');
    }
  };

  // 添加 useEffect 在组件加载时自动获取随机场景
  useEffect(() => {
    getRandomScene();
  }, []); // 空数组表示仅在组件首次加载时执行

  return (
    <div className="container">
      <h1 className="title">驾驶灯光情景练习</h1>
      <button className="scene-button" onClick={getRandomScene}>
        随机场景
      </button>
      {randomScene && (
        <div className="game-container">
          <h2 className="scene-text">场景: {randomScene}</h2>
          <div className="options-container">
            {lights.map((light) => (
              <label key={light.name} className="option-label">
                <input
                  type="radio"
                  value={light.name}
                  checked={selectedLight === light.name}
                  onChange={handleSelect}
                  className="radio-input"
                />
                <span className="radio-text">{light.name}</span>
              </label>
            ))}
          </div>
          {feedback && (
            <div className={`feedback ${feedbackType}`}>
              {feedback}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default App;
